import { default as indexZOuxzYM4ddMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexWTAFenA7BbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/index.ts?macro=true";
import { default as PageFilterskv4Po8XAlrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionGenresPDlofSoRSAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMovieshmkk3hUJ9iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularwIyLO6pfqgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeries7Y58veoRFPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersGHvc5bVRcgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresWuVcvN58geMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_936ibsC97gtaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexSrNKtljUqvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93SqN4Ig876vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/collections/[slug].vue?macro=true";
import { default as index45Cr0HIx0vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/collections/index.vue?macro=true";
import { default as copyrightRN8JzNaTexMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/copyright.vue?macro=true";
import { default as _91id_93iwRUPPuTDWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/crew/[id].vue?macro=true";
import { default as indexUis01q1KqQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/faq/index.vue?macro=true";
import { default as all4J4KFS4K99Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtZhhYijdY16Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueLg910F1Yd0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesAA0Ijba4cqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewed3jAGW1mCK1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continuesZk2vkvjN5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/continue.vue?macro=true";
import { default as indexy1w16nvTynMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/index.vue?macro=true";
import { default as purchased9Hp1cZQgrEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedLBCfA1GJUUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policywWrd7yPhujMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesuA3lFTg03mMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionCollectionsmzRO26SkuUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueCdASxII3UrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsVxunevsTgtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresTav1fRnFA5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderXChOKovKECMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularDJy99FC4vWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersGohyjCkLN5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeries42B1x80bT9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsPGFKPwhSnxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeOeqhCrDzGNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcomingX7XFyM1cegMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexnY4UIeMwupMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93qQMi2cLYZzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallIInFJKhYyHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstalltBgIhRLLzdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as types1yg30uvySAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/types.ts?macro=true";
import { default as useInstallYT8XD5fEwcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewKMZWiun1Y8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexeWzCAA5vPbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsPSTqlVDPesMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrew5Y67fRp44hMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionU3vuWPwRSfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderC8phFjaLFHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeJYekixBid2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeleton5SBj51oszvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtonzVsr9TTCAIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedKhBMEu8URZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesKqEM7unsuvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersCiKHnfbdVxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsbMjb3Oa57GMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93AeyRQOVIBcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/news/[slug].vue?macro=true";
import { default as indexY6ELRvh8hNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsmAm4MwumDOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesRiWCrmipMhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreements7jgByrFSRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightmSikbMh8aMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderB9q5rueiQCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateYNZGEPfil0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-private.vue?macro=true";
import { default as policy_45userWW2KvaZfQfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-user.vue?macro=true";
import { default as premiers5Ehlsd8mirMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/premiers.vue?macro=true";
import { default as private_45policyWKOgWK9WTOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/private-policy.vue?macro=true";
import { default as indexvw2CH98EtZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalpTsnjLezq0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfiletYaOOo0LjOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalHmoJE0HACiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VResumeSubscriptionSuccessModal7X66j95lBZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerR66rfnztTyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationbjQILOXg9KMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryUsmNYZAoyGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardoVVPpURPAsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettings1WmxA8IyX6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsIddOOMyf9lMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsGvijyfckwuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramazx2eU0nxVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsgqlXyobG4uMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as index7g6groOZfDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/devices/index.vue?macro=true";
import { default as indexZLdKXJBAQMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/index.vue?macro=true";
import { default as indexnYUhmJYugeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/notifications/index.vue?macro=true";
import { default as index4todLgd0pzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/payments/index.vue?macro=true";
import { default as indexDPG70gEWRlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/settings/index.vue?macro=true";
import { default as resultYQUtecnWIwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/result.vue?macro=true";
import { default as _91slug_936vs92ejqlJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/similar/[slug].vue?macro=true";
import { default as indexl1nUJJUqfmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/sports/channels/index.vue?macro=true";
import { default as index1IRp5vc0aNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsB31KPb8fJqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentqRMITsMRdDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesfLDvtR5ek9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownload0ZAsObqj1kMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderZfTsYUNXshMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsY7uzelm5zLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverYIaEyvr8adMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexyti7g7CobEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/index.vue?macro=true";
import { default as termsOPOKqe7Y2xMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/terms.vue?macro=true";
import { default as upcomingak4kITyA67Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexvw2CH98EtZMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: index7g6groOZfDMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexZLdKXJBAQMMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexnYUhmJYugeMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: index4todLgd0pzMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexDPG70gEWRlMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: index1IRp5vc0aNMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-309147/src/pages/upcoming.vue").then(m => m.default || m)
  }
]