import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import type { DirectiveBinding } from 'vue';
import type { ITooltipProps } from '~/types/directives';

export default defineNuxtPlugin((nuxtApp) => {
  const getPreparedProps = (props: ITooltipProps = {}) => ({
    ...props,
    content: props.content || '',
    placement: props.placement || 'top',
    zIndex: props.zIndex || 99999,
    theme: props.theme || 'default',
  });

  const initTooltip = (el, props: ITooltipProps = {}) => {
    el._tippyInstance = tippy(el, getPreparedProps(props));
  };

  const destroyTooltip = (el) => {
    el._tippyInstance.destroy();
    el._tippyInstance = null;
  };


  nuxtApp.vueApp.directive('tooltip', {
    mounted(el, binding: DirectiveBinding<ITooltipProps | null>) {
      if (binding.value && binding.value?.content) {
        initTooltip(el, binding.value);
      }
    },
    updated(el, binding: DirectiveBinding<ITooltipProps | null>) {
      if (!binding.value || !binding.value?.content) {
        destroyTooltip(el);
        return;
      }
      if (el._tippyInstance) {
        el._tippyInstance.setProps(getPreparedProps(binding.value));
      } else {
        initTooltip(el, binding.value);
      }
    },
    unmounted(el) {
      if (el._tippyInstance) {
        destroyTooltip(el);
      }
    },
  })
})
