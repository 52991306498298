import { defineStore } from 'pinia';
import { injectScript } from '~/helpers/integrations';

export const useCaptchaStore = defineStore('useCaptchaStore', () => {
  const isCaptchaChecking = ref(false);
  let captchaCallback = () => {};

  const config = useRuntimeConfig();
  const captchaDivId = 'captcha-container';
  const captchaScriptUrl = 'https://smartcaptcha.yandexcloud.net/captcha.js';
  let resolveCaptchaLoad = () => {};
  const captchaLoadedPromise = new Promise(resolve => {
    resolveCaptchaLoad = resolve;
  });

  const renderCaptcha = () => {
    if (document.querySelector(`#${captchaDivId}`)) {
      return;
    }

    const captchaScript = new injectScript(captchaScriptUrl);
    const div = document.createElement('div');
    div.id = captchaDivId;
    document.body.appendChild(div);

    captchaScript.load(() => {
      const captchaId = window.smartCaptcha.render(captchaDivId, {
        sitekey: config.public.recaptcha,
        invisible: true,
        hideShield: true,
        callback: () => {
          isCaptchaChecking.value = false;
          captchaCallback();
        },
      });
      window.smartCaptcha.subscribe(captchaId, 'challenge-hidden', () => {
        isCaptchaChecking.value = false;
      });
      resolveCaptchaLoad();
    });
  };

  const startCheckCaptcha = async (callback = () => {}) => {
    isCaptchaChecking.value = true;
    try {
      await captchaLoadedPromise;
      captchaCallback = callback;
      window.smartCaptcha.reset();
      window.smartCaptcha.execute();
    } catch (e) {
      console.error(e);
      isCaptchaChecking.value = false;
    }
  };

  const getCaptchaToken = (): string | undefined => window.smartCaptcha?.getResponse();

  return {
    isCaptchaChecking,
    renderCaptcha,
    startCheckCaptcha,
    getCaptchaToken,
  };
});