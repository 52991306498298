<script setup lang="ts">
  import VHeader from '~/components/header/Index.vue';
  import Navbar from '~/components/controlBar/ControlBar.vue';
  import VModalConductor from '~/components/modals/VModalConductor.vue';
  import VFooter from '~/components/footer/Index.vue';

  const url = useRequestURL();
  useServerSeoMeta({
    title:
      'Фильмы, сериалы и мультфильмы смотреть онлайн бесплатно в хорошем качестве HD и FullHD | Переведено и озвучено по заказу онлайн-кинотеатра FLEX',
    description:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    ogTitle:
      'Фильмы, сериалы и мультфильмы смотреть онлайн бесплатно в хорошем качестве HD и FullHD | Переведено и озвучено по заказу онлайн-кинотеатра FLEX',
    ogDescription:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    ogImage: '/images/Flex_1024x500.webp',
    ogUrl: url.href,
    twitterTitle:
      'Фильмы, сериалы и мультфильмы смотреть онлайн бесплатно в хорошем качестве HD и FullHD | Переведено и озвучено по заказу онлайн-кинотеатра FLEX',
    twitterDescription:
      'Смотреть онлайн или в дороге зарубежные фильмы, сериалы и мультфильмы в FullHD качестве и профессиональной озвучке TVShows эксклюзивно для FLEX без рекламы. Приложение FLEX доступно в App Store и Google Play',
    twitterImage: '/images/Flex_1024x500.webp',
    twitterCard: 'summary',
  });

  useHead({
    htmlAttrs: {
      lang: 'ru',
    },
    link: [
      {
        rel: 'icon',
        href: '/icons/favicon-1-rounded.png',
        sizes: '32x32',
        type: 'image/png',
      },
      {
        rel: 'icon',
        href: '/icons/favicon-6-rounded.png',
        sizes: '96x96',
        type: 'image/png',
      },
      {
        rel: 'icon',
        href: '/icons/favicon-12-rounded.png',
        sizes: '192x192',
        type: 'image/png',
      },
    ],
  });
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  onMounted(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
  });
</script>

<template>
  <v-header />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <v-footer />
  <navbar />
  <v-modal-conductor />
  <NuxtPwaManifest />
</template>

<style lang="scss">
  .hidden-title {
    position: absolute;
    left: -9999rem;
    top: -9999rem;
    opacity: 0;
  }
</style>
