import type { IMetricSuccessFilmData, IMetricSuccessTariffData, IPurchaseLayer, TMetricFilmBuy } from '~/types/metric';

export const useMetrics = () => {
  const config = useRuntimeConfig();
  const isTargetKgDomain = useState('isTargetKgDomain');
  const { reachGoal } = useYandexMetrika();

  const metricGoals = {
    registrationSuccess: 'confirm_registration',
    authSuccess: 'auth_success',
    authModalOpened: 'open_authregwindow',
    clickWatchForMoney: 'click_watchfrommoney',
    clickWatchFree: 'click_watchfree_button',
    clickWatchBySub: 'click_watchsubscription_button',
    clickCopyReferalLink: 'copy_referral_link',
    tariffModalOpened: 'tarifkoplate_vitrina',
    clickRentSd: 'click_arenda_sd',
    clickRentHd: 'click_arenda_hd',
    clickBuyFilm: 'kupitfilm_korzina',
    clickBuyFilmByBonus: 'kupitfilm_korzina_bonus',
    successPayedFilmByBonus: 'purchase_film_bonus',
  } as const;

  const filmPaymentDataByType: Record<
    TMetricFilmBuy,
    {
      goalId: string;
      variant: string;
    }
  > = {
    purchase: {
      goalId: config.public.yaMetricData.buyFilmId,
      variant: 'Покупка фильма',
    },
    hd_lease: {
      goalId: config.public.yaMetricData.rentFilmHdId,
      variant: 'Аренда в HD',
    },
    sd_lease: {
      goalId: config.public.yaMetricData.rentFilmSdId,
      variant: 'Аренда в SD',
    },
  };

  type TGoal = (typeof metricGoals)[keyof typeof metricGoals];

  // const reachGoal = (goalName: TGoal) => {
  //   ym(config.public.yandexId, 'reachGoal', goalName);
  // };

  const pushPurchaseLayer = (purchase: IPurchaseLayer) => {
    dataLayer.push({
      ecommerce: {
        currencyCode: isTargetKgDomain.value ? 'KGS' : 'RUB',
        purchase,
      },
    });
  };

  const reachSuccessTariffPayment = (data: IMetricSuccessTariffData) => {
    pushPurchaseLayer({
      actionField: {
        id: Number(data.paymentId),
        goal_id: config.public.yaMetricData.buyTariffId,
        ...(data.coupon ? { coupon: data.coupon } : {}),
      },
      products: [
        {
          name: data.tariffName,
          price: data.tariffPrice,
          category: 'Покупка тарифа',
          quantity: 1,
        },
      ],
    });
  };

  const reachSuccessFilmPayment = (data: IMetricSuccessFilmData) => {
    pushPurchaseLayer({
      actionField: {
        id: data.paymentId,
        goal_id: filmPaymentDataByType[data.type].goalId,
      },
      products: [
        {
          name: data.filmName,
          price: data.price,
          category: 'Оплата фильма',
          variant: filmPaymentDataByType[data.type].variant,
          quantity: 1,
        },
      ],
    });
  };

  return {
    metricGoals,
    reachGoal,
    reachSuccessTariffPayment,
    reachSuccessFilmPayment,
  };
};
